<script setup lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import Task from '~~/app/task/models/Task';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import TaskSchema from '~~/app/task/schemas/TaskSchema';
    import moment from '~~/utils/moment';
    import '@vuepic/vue-datepicker/dist/main.css';

    const props = defineProps({
  task: null
});

    const submenu = ref(null);
    const isDisplayed = computed(() => submenu.value?.isDisplayed);
    const updateDueDate = async (date: string | null) => {
        submenu.value?.hide();

        props.task.dueDate = date ? moment(date) : null;
        useEvent('task:task:updated', props.task);

        const endpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
        const schema = new TaskSchema({
            id: props.task.getId(),
            attributes: {
                dueDate: date ? moment(date).toUtcDateTimeString() : null,
            },
        });

        await endpoint.withoutEvents().update(schema);
    };

    defineExpose({
        isDisplayed,
    });
</script>

<template>
    <Submenu
        ref="submenu"
        without-max-height
        position="right-0"
    >
        <template #default="{ toggle }">
            <div
                class="flex items-center @sm:justify-end space-x-2 transition-all cursor-pointer group"
                :class="task.isDue ? 'text-red-600 hover:text-red-500' : 'text-gray-600 hover:text-black'"
                @click.prevent.stop="toggle"
            >
                <span
                    v-if="task.dueDate"
                    class="text-xs @sm:text-sm"
                >
                    {{ task.dueDate.toShortDayDateString() }}
                </span>
                <div class="w-[16px] text-center">
                    <div
                        class="hidden"
                        :class="{ 'group-hover:block': task.dueDate }"
                        @click.prevent.stop="updateDueDate(null)"
                    >
                        <i class="text-xs @sm:text-sm fa-regular fa-xmark"></i>
                    </div>
                    <div :class="{ 'block group-hover:hidden': task.dueDate }">
                        <i class="text-xs @sm:text-sm fa-regular fa-calendar"></i>
                    </div>
                </div>
            </div>
        </template>
        <template #submenu="{ hide }">
            <div class="py-2">
                <slot
                    name="datepicker"
                    :hide="hide"
                >
                    <VueDatePicker
                        v-model="task.dueDate"
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        hide-input-icon
                        inline
                        :locale="useGetCurrentMomentLocale()"
                        auto-apply
                        :clearable="false"
                        @update:model-value="updateDueDate"
                    />
                </slot>
            </div>
        </template>
    </Submenu>
</template>
