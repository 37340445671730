<script setup lang="ts">
    import UserCollection from '~/app/auth/collections/UserCollection';
    import User from '~~/app/auth/models/User';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import Task from '~~/app/task/models/Task';
    import TaskSchema from '~~/app/task/schemas/TaskSchema';

    const props = defineProps({
  task: null,
  position: { default: 'right-0' }
});
    const select = ref(null);

    const updateUsers = async (users: Array<User> | null) => {
        const endpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
        const schema = new TaskSchema({
            id: props.task.getId(),
        });

        props.task.setRelationship('users', UserCollection.makeFromModels(users || []));
        useEvent('task:task:updated', props.task);

        if (!users) {
            schema.addEmptyToManyRelationship('users');
            return await endpoint.withoutEvents().update(schema);
        }

        for (let i = 0; i < users.length; i++) {
            schema.addToManyRelationship('users', ModelType.USERS, users[i].getId());
        }

        await endpoint.withoutEvents().update(schema);
    };

    const isDisplayed = computed(() => select.value?.isDisplayed);
    defineExpose({
        isDisplayed,
    });
</script>

<template>
    <SelectUser
        ref="select"
        :submenu-position="position"
        placeholder-container-class=""
        :multiple="true"
        :close-on-select="false"
        :initial-value="task.users && task.users.isNotEmpty() ? task.users.toArray() : null"
        @hidden="updateUsers"
    >
        <template #selected="slotProps">
            <UsersAvatarList
                :users="slotProps.selected"
                :clickable-avatar="false"
                negative-margin="-ml-4"
                size="w-[20px] h-[20px] @sm:w-[28px] @sm:h-[28px]"
            ></UsersAvatarList>
        </template>
        <template #placeholder>
            <div
                class="w-[20px] h-[20px] @sm:w-[28px] @sm:h-[28px] border hover:border-solid border-dashed brder-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-400 hover:text-black transition-all cursor-pointer rounded-full"
                :data-tooltip="$t('selects.user.placeholder')"
            >
                <i class="text-xs fa-regular fa-user" />
            </div>
        </template>
    </SelectUser>
</template>
