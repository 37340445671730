<script setup lang="ts">
    import Task from '~~/app/task/models/Task';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import TaskSchema from '~~/app/task/schemas/TaskSchema';
    import { storeToRefs } from 'pinia';
    import { onClickOutside } from '@vueuse/core';

    const { authed } = storeToRefs(useAuthStore());
    const props = defineProps({
  task: null
});

    const displayTitleInput = ref(false);
    const inputTitle = ref(null);
    const titleBox = ref(null);

    onClickOutside(titleBox, (event) => updateTitle());

    const showTitleInput = async () => {
        displayTitleInput.value = true;
        await nextTick();
        inputTitle.value.focus();
    };

    const updateTitle = async () => {
        if (!displayTitleInput.value) {
            return;
        }

        displayTitleInput.value = false;

        const endpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
        const schema = new TaskSchema({
            id: props.task.getId(),
            attributes: {
                title: props.task.title,
            },
        });

        await endpoint.withoutEvents().update(schema);
    };
</script>

<template>
    <div
        class="cursor-text"
        :class="displayTitleInput ? 'w-full' : 'w-auto'"
        ref="titleBox"
    >
        <template v-if="displayTitleInput">
            <input
                ref="inputTitle"
                type="text"
                :class="$theme('form.input_xs', 'w-full')"
                v-model="task.title"
                @blur="updateTitle()"
                @keyup.enter="updateTitle()"
            />
        </template>
        <template v-else>
            <p
                class="text-sm"
                @click.prevent.stop="showTitleInput()"
                :class="{ 'line-through text-gray-600': task.isCompleted }"
            >
                {{ task.title }}
            </p>
        </template>
    </div>
</template>
